<template>
	<div class="app-main__inner">
		<div class="absolute bg-red-30 inset-0" style="z-index:999" @click="hideAllFilter()"
			 v-if="showFilter || showTopFilter"></div>
		<div class="absolute bg-red-00 inset-0 z-10" v-if="statusUpdate" @click="hideStatus"></div>
		<div class="absolute bg-red-0 inset-0 z-10" v-if="openDownloadFilter" @click="openDownloadFilter = false"></div>
		<div class="container mb-3 dm-sans-font">
			<div class="row flex flex-col md:flex-row justify-between align-items-center">
				<div class="flex">
					<div>
						<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer"
							 v-if="tab === 1">
							<span data-cy='article-heading'
								  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
									$t('articles')
								}}</span>
						</div>
					</div>
					<div class="flex justify-between align-items-center space-x-3 mb-3" v-if="tab === 2">
						<span data-cy='price-manage'
							  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('priceManagement')
							}}</span>
					</div>
					<div class="flex justify-between align-items-center space-x-3 mb-3" v-if="tab === 3">
						<span data-cy='price-manage'
							  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('unittypes')
							}}</span>
					</div>
					<div class="flex ml-3" v-if="tab === 1">
						<span v-on:click="openCreateArticle"
							  class="text-xs h-10 sm-center create-order-btn cursor-pointer lg:pt-1"
						><svg width="20"
							  height="24"
							  viewBox="0 0 20 24"
							  fill="none"
							  xmlns="http://www.w3.org/2000/svg">
									<path
										d="M11.4735 0C11.7764 0 12.0093 0.247059 12.0093 0.541176V4.32941C12.0093 6.48235 13.7682 8.24706 15.8998 8.25882C16.7851 8.25882 17.484 8.27059 18.0198 8.27059C18.3809 8.27059 18.975 8.25882 19.4758 8.25882C19.767 8.25882 20 8.49412 20 8.78823V18.2471C20 21.1647 17.6587 23.5294 14.7583 23.5294H5.49796C2.46942 23.5294 0 21.0471 0 17.9882V5.30588C0 2.38824 2.35294 0 5.25335 0H11.4735ZM9.78451 9.14118C9.30693 9.14118 8.91089 9.52941 8.91089 10.0118V12.0471H6.9074C6.42982 12.0471 6.03378 12.4353 6.03378 12.9294C6.03378 13.4118 6.42982 13.8 6.9074 13.8H8.91089V15.8353C8.91089 16.3176 9.30693 16.7059 9.78451 16.7059C10.2621 16.7059 10.6465 16.3176 10.6465 15.8353V13.8H12.6616C13.1392 13.8 13.5352 13.4118 13.5352 12.9294C13.5352 12.4353 13.1392 12.0471 12.6616 12.0471H10.6465V10.0118C10.6465 9.52941 10.2621 9.14118 9.78451 9.14118ZM13.7054 1.06612C13.7054 0.559059 14.3146 0.307294 14.6629 0.673176C15.9221 1.99553 18.1224 4.30729 19.3525 5.59906C19.6926 5.95553 19.4433 6.54729 18.9529 6.54847C17.9955 6.552 16.8667 6.54847 16.0549 6.54023C14.7666 6.54023 13.7054 5.46847 13.7054 4.16729V1.06612Z"
										fill="#E8B105"/>
									</svg>
					</span>
					</div>
				</div>
				<div class="div-action">
					<div class="div-excel-action-select">
                          <span
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md">
                             <button type="button" class="btn btn-orange"
									 v-on:click="oNopenDownloadFilter">{{ $t('excelReport') }} <i
								 class="lnr-chevron-down ml-1" style="font-size: 12px"></i></button>
							  <!--                             <select v-on:change="executeExcelAction($event)" class="excel-action-select flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md text-gray-500">-->
							  <!--                                 <option value="">Action..</option>-->
							  <!--                                 <option value="upload_excel_product">Upload Excel product</option>-->
							  <!--                                  <option value="download_excel_product" >Export product</option>-->
							  <!--                                 <option value="download_excel_template">Download Template</option>-->
							  <!--                             </select>-->
                         </span>
						<div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-20" style="z-index:9999"
							 v-if="openDownloadFilter">
							<div class="bg-white border-b">
								<label class="inline-flex align-items-center item-center mt-1 ml-3">
									<span class="ml-2 cursor-pointer"
										  v-on:click="executeExcelAction('upload_excel_product')">{{
											$t('upload_bulk_article_excel')
										}}</span>
								</label>
								<label class="inline-flex align-items-center item-center mt-1 ml-3">
									<span class="ml-2 cursor-pointer"
										  v-on:click="executeExcelAction('download_excel_product')">{{
											$t('export_product_data')
										}}</span>
								</label>
								<label class="inline-flex align-items-center item-center mt-1 ml-3">
									<a href="../../assets/article/b2b_article_template.xlsx"
									   v-on:click="openDownloadFilter=false" download target="_blank"
									   class="ml-2 cursor-pointer">{{ $t('download_excel_template') }}</a>
								</label>
							</div>
						</div>
					</div>
					<div
						class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl">
						<span data-cy='tab1' @click="changeTab(1)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md"
							  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('articles')
							}}</span>
						<span data-cy='tab2' @click="changeTab(2)"
							  class="v-article-7 text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2"
							  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('priceManagement')
							}}</span>
						<span data-cy='tab2' @click="changeTab(3)"
							  class="v-article-7 text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2"
							  :class="tab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('unittypes')
							}}</span>
						<span data-cy='category-drawer'
							  class="cursor-pointer h-10  flex justify-center align-items-center px-3 py-2"
							  @click="openCategoryDrawer">{{ $t('categoryManagement') }}</span>
					</div>
				</div>
			</div>
			<div class="row" v-show="tab === 1">
				<div class="col-md-12 mt-2">
					<span v-if="GET_ARTICLE_ACTIVE_FILTER.length > 0"
						  class="flex block text-gray-400 justify-end items-center text-xs mb-2">
                <span class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24"
						 stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
						  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"/>
                    </svg>
                </span>
                {{ $t('activeFilter') }}
            </span>
					<div class="flex flex-wrap md:space-x-4 justify-end mb-2 pr-2">
                		<span class="flex justify-between space-x-3 font-semibold text-blue-900 text-xs"
							  v-for="(item, i) in GET_ARTICLE_ACTIVE_FILTER" :key="item.mode">
                    <span>
                        {{ item.mode === 'has_images' ? $t('productWithImage') : null }}
                        {{ item.mode === 'is_relevant' ? $t('relevantArticle') : null }}
                        {{ item.mode === 'customer_specific' ? $t('CustomerSpecific') : null }}
                    </span>
                    <span class="cursor-pointer" @click="removeItemFromArticleFilter(item)">x</span>
                </span>
					</div>
				</div>
				<div
					class="flex flex-col md:flex-row justify-center lg:justify-between w-full align-items-center space-x-4 mb-4 bg-white border rounded-md p-2 w-full">
					<!--						GET_ARTICLES_CHECKED && GET_ARTICLES_CHECKED.status-->
					<div class="flex flex-col md:flex-row justify-center lg:justify-between ">
							<span class="mb-2 mr-3 md:mb-0">
                            <button
								class="flex justify-between align-items-center space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
								@click="openAssignProduct">{{ $t('productArticleCategoryAssign') }}</button>
                        </span>
						<span class="mb-2 mr-3 md:mb-0 cursor-pointer">
                            <span @click="showFilter = !showFilter"
								  class="flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md">
                                <span><img src="./../../assets/images/icons/Frame.png" alt=""></span>
                                <span class="text-gray-500">{{ $t('filter') }}</span>
                            </span>
                            <div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-20" style="z-index:9999"
								 v-if="showFilter">
                                    <FilterProductComponent :tab='1'
															@hideAdvanceFilter="showFilter = false"
															@filterVatRate='filterVatRate'

															@filterCustomerSpecific='filterCustomerSpecific'
															@filterRelevantArticles='filterRelevantArticles'
									/>
                                </div>
                        </span>
						<!-- @filterArticleWithImage='filterArticleWithImage' <span class="mb-2 md:mb-0 cursor-pointer" @click="showFilter = !showFilter">
							<span class="flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md">
								<span><img src="./../../assets/images/icons/Frame.png" alt=""></span>
								<span class="text-gray-500">{{ $t('filter') }}</span>
							</span>
						</span> -->
						<!-- <div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-20 md:mt-44" style="z-index:9999" v-if="showFilter">
							<FilterProductComponent :top="true"
								@hideAdvanceFilter="showTopFilter = false"
								@filterVatRate='filterVatRate'
							/>
						</div> -->
						<span class="mb-2 md:mb-0">
                            <el-tooltip :content="$t('updatePriceList')" v-if="showPricelistSelect">
                                <span class="flex absolute h-3 w-3 cursor-pointer">
                                    <span class="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></span>
                                    <span
										class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                                </span>
                            </el-tooltip>
                            <select :disabled="updatingPricelist"
									class="flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
									v-model="priceList.id" @change="updateArticlePricelist">
                                <option value="" selected disabled>{{ $t('priceListSelection') }}</option>
                                <option :value="price.id" v-for="price in GET_PRICE_LIST"
										:key="price.id">{{ price.description + `(${price.code})` }}</option>
                            </select>
                        </span>
					</div>
					<div class="mb-2 md:mb-0 w-full lg:w-4/12">
						<input type="text" v-model="searchName" @input="searchInputTrigger"
							   class="flex w-full justify-between align-items-center space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
							   v-bind:placeholder="$t('search')">
					</div>
				</div>
				<!--				<div class="col-md-12 mt-2 flex justify-center lg:justify-end mb-4 bg-white border rounded-md p-2 w-full">-->

				<!--					-->
				<!--				</div>-->
				<div data-cy='table1' class="w-full mt-3"> <!--pl-5 pr-5-->
					<div class="table-responsive rounded-lg bg-white border">
						<table v-loading='loading' style="width: 100%;" id="exampledestroy"
							   class="table table-hover table-bordered">
							<thead>
							<tr>
								<th width='10px'><input type="checkbox" name="checkboxAll" v-model="checkboxAll"
														style="font-size: 10px !important"
														class="font-xs checkbox-height v-article-5"
														@change="toggleAllCheckbox"/></th>
								<th class="text-xs" width='60px'>
									<div class="d-flex justify-content-between align-items-center">
										{{ $t('articleNumber') }}<span class="flex flex-col"><i
										@click="sortArticle('article_number')"
										class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortArticle('-article_number')"
										class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs v-article-6" width='100px '>{{ $t('category') }}</th>
								<th class="text-xs" width='70px'>
									<div class="d-flex justify-content-between align-items-center">{{
											$t('description')
										}}<span class="flex flex-col"><i @click="sortArticle('description')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortArticle('-description')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs" width='100px'>
									<div
										class="d-flex justify-content-between align-items-center whitespace-nowrap mr-2">
										{{ $t('productVideo') }}<span class="flex flex-col"><i
										@click="sortArticle('product_video_url')"
										class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortArticle('-product_video_url')"
										class="lnr-chevron-down cursor-pointer small"></i></span>
									</div>
								</th>
								<th class="text-xs whitespace-nowrap v-article-4" width='100px'>{{
										$t('relevantArticle')
									}}
								</th>
								<th class="text-xs v-article-3" width='100px'>
									<div
										class="d-flex justify-content-between align-items-center whitespace-nowrap mr-2">
										{{ $t('thumnails') }}<span class="flex flex-col"><i @click="sortImageAsc"
																							class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortImageDesc" class="lnr-chevron-down cursor-pointer small"></i></span>
									</div>
								</th>
								<th class="text-xs w-24 v-article-2">{{ $t('productImageUpload') }}</th>
								<th class="text-xs w-20 v-article-2">{{ $t('actions') }}</th>
							</tr>
							</thead>
							<tbody v-for="(article, i) in GET_ARTICLES.data" :key="i">
							<ArticleTable :data="article" :index="i" :key="`${i}_${article.article_number}`"
										  @openArticleModal='openArticleModal'
										  @openModalImage='openModalImage'
										  @filterArticleRelevant='filterArticleWithRelevant'
										  @checkArticleRelevant='relevantArticlesProcess'
										  @openArticleUpdate='openArticleUpdate'/>

							<!-- <tr v-if="history.Invoices !== undefined && history.Invoices.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
							</tbody>
						</table>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="50" selected>50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                </select>
                            </span>
                        </span>
						<div class="pagination-block" v-if="GET_ARTICLES.data"> <!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_ARTICLES.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-3" v-show="tab === 2">
				<div class="col-md-12 mt-2">
					<span v-if="GET_ARTICLE_PRODUCT_PRICE_FILTER.length > 0"
						  class="flex block text-gray-400 justify-end items-center text-xs mb-2">
                <span class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24"
						 stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
						  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"/>
                    </svg>
                </span>
                {{ $t('activeFilter') }}
            </span>
					<div class="flex flex-wrap md:space-x-4 justify-end mb-2 pr-2">
                		<span class="flex justify-between space-x-3 font-semibold text-blue-900 text-xs"
							  v-for="(item, i) in GET_ARTICLE_PRODUCT_PRICE_FILTER" :key="item.mode">
                    <span>
                        {{ item.mode === 'quantity' ? $t('quantity') : null }}
                        {{ item.mode === 'vat_rate' ? $t('vatRate') : null }}
                        {{ item.mode === 'margin' ? $t('margin') : null }}
                        {{ item.mode === 'cost' ? $t('costPrice') : null }}
                        {{ item.mode === 'sales' ? $t('salesPrice') : null }}
                    </span>
                    <span class="cursor-pointer" @click="removeItemFromArticlePriceFilter(item)">x</span>
                </span>
					</div>
				</div>
				<div class="w-full flex justify-center md:justify-end mb-4 bg-white border rounded-md p-2">
					<div
						class="flex w-full flex-col md:flex-row justify-center lg:justify-end align-items-center space-x-4">
						<div class="flex justify-between align-items-center space-x-4">
                            <span class="mb-2 md:mb-0 cursor-pointer" @click="undoSelection" v-if="showUndoBtn">
                                <span
									class="flex justify-between align-items-center space-x-4 text-white bg-red-500 border px-3 py-2 rounded-md">
                                    {{ $t('undoSelection') }}
                                    <span><i class="ml-1 mdi mdi-undo"></i></span>
                                </span>
                            </span>

							<span class="mb-2 md:mb-0 cursor-pointer"
								  v-if="GET_ARTICLES_CHECKED && GET_ARTICLES_CHECKED.status">
                                <span @click="updateBulkData"
									  :style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
									  :class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-blue-800'"
									  class="flex justify-between align-items-center space-x-4 text-white border px-3 py-2 rounded-md">
                                    <span class="flex space-x-3" v-if="updating">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-gray-400"
											 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('updating') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('bulkUpdate') }}
                                    </span>
                                </span>
                            </span>
						</div>
						<span class="mb-2 md:mb-0 cursor-pointer" @click="showFilter = !showFilter">
                            <span
								class="flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md">
                                <span><img src="./../../assets/images/icons/Frame.png" alt=""></span>
                                <span class="text-gray-500">{{ $t('filter') }}</span>
                            </span>
                        </span>
						<div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-20 md:mt-44"
							 style="z-index: 9999;top: 34px;" v-if="showFilter">
							<FilterProductComponent :tab='2'
													@hideAdvanceFilter="showFilter = false"
													@filterVatRate='filterVatRate'
													@filterSalesPrice='filterSalesPrice'
													@filterCostPrice='filterCostPrice'
													@filterQuantity='filterQuantity'
							/>
						</div>
						<span class="mb-2 md:mb-0">
                            <el-tooltip :content="$t('updatePriceList')" v-if="showPricelistSelect">
                                <span class="flex absolute h-3 w-3 cursor-pointer">
                                    <span class="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></span>
                                    <span
										class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                                </span>
                            </el-tooltip>
                            <select :disabled="updatingPricelist"
									class="flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
									v-model="priceList.id" @change="updateArticlePricelist">
                                <option value="" selected disabled>{{ $t('priceListSelection') }}</option>
                                <option :value="price.id" v-for="price in GET_PRICE_LIST"
										:key="price.id">{{ price.description + `(${price.code})` }}</option>
                            </select>
                        </span>
						<span class="mb-2 md:mb-0 w-2/6">
                            <input type="text" v-model="searchName" @input="searchInputTrigger"
								   class="flex justify-between w-full align-items-center space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
								   v-bind:placeholder="$t('search')">
                        </span>
					</div>
				</div>

				<div class="w-full">
					<div data-cy='tabledata' class="tabledata">
						<div class="table-responsive bg-white rounded-md">
							<table v-loading='loading' style="width: 100%;" id="exampledestroy"
								   class="table table-hover table-bordered">
								<thead>
								<tr>
									<th width='10px'><input type="checkbox" name="checkboxAll" v-model="checkboxAll"
															style="font-size: 10px !important"
															class="font-xs checkbox-height"
															@change="toggleAllCheckbox"/></th>
									<th class="text-xs" width='100px'>
										<div class="d-flex justify-content-between align-items-center">
											{{ $t('articleNumber') }}<span class="flex flex-col"><i
											@click="sortArticle('article_number')"
											class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortArticle('-article_number')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
									</th>
									<th class="text-xs" width='70px'>
										<div class="d-flex justify-content-between align-items-center">
											{{ $t('description') }}<span class="flex flex-col"><i
											@click="sortArticle('description')"
											class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortArticle('-description')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
									</th>
									<th class="text-xs" width='70px'>
										<div class="d-flex justify-content-between align-items-center">{{
												$t('quantity')
											}}<span class="flex flex-col"><i @click="sortArticle('quantity_in_stock')"
																			 class="lnr-chevron-up cursor-pointer small"></i><i
												@click="sortArticle('-quantity_in_stock')"
												class="lnr-chevron-down cursor-pointer small"></i></span></div>
									</th>
									<th class="text-xs" width='70px'>
										<div class="d-flex justify-content-between align-items-center">
											{{ $t('salesPrice') }}<span class="flex flex-col"><i
											@click="sortArticle('sales_price')"
											class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortArticle('-sales_price')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
									</th>
									<th class="text-xs" width='70px'>
										<div class="d-flex justify-content-between align-items-center">
											{{ $t('costPrice') }}<span class="flex flex-col"><i
											@click="sortArticle('purchase_price')"
											class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortArticle('-purchase_price')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
									</th>
									<th class="text-xs" width='70px'>
										<div class="d-flex justify-content-between align-items-center">{{
												$t('vatRate')
											}}<span class="flex flex-col"><i @click="sortArticle('vat_rate')"
																			 class="lnr-chevron-up cursor-pointer small"></i><i
												@click="sortArticle('-vat_rate')"
												class="lnr-chevron-down cursor-pointer small"></i></span></div>
									</th>
									<th class="text-xs" width='70px'>
										<div class="d-flex justify-content-between align-items-center">{{
												$t('margin')
											}}<span class="flex flex-col"><i @click="sortAscMargin"
																			 class="lnr-chevron-up cursor-pointer small"></i><i
												@click="sortMarginDesc"
												class="lnr-chevron-down cursor-pointer small"></i></span></div>
									</th>


									<!-- <th class="text-xs whitespace-nowrap" width='100px'>{{ $t('quantity') }}</th>
									<th class="text-xs whitespace-nowrap" width='100px'>{{ $t('salesPrice') }}</th>
									<th class="text-xs whitespace-nowrap" width='100px'>{{ $t('costPrice') }}</th>
									<th class="text-xs whitespace-nowrap" width='100px'>{{ $t('vatRate') }}</th>
									<th class="text-xs whitespace-nowrap" width='100px'>{{ $t('margin') }}</th> -->
									<!-- <th class="text-xs" width='100px'>{{ $t('thumnails') }}</th>
									<th class="text-xs w-24">{{ $t('action') }}</th> -->
								</tr>
								</thead>
								<tbody v-for="(article, i) in GET_ARTICLES.data" :key="i">
								<ArticleSpreadSheet :id="i" :data="article"
													@updateQuantity='updateArticleQuantity'
													@updateSalesPrice='updateArticlePrice'
													@updatePurchasePrice='updateArticleCostPrice'
													@updateMarginValue='updateArticleMargin'
								/>

								</tbody>
							</table>
						</div>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="50" selected>50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                </select>
                            </span>
                        </span>
						<div class="pagination-block" v-if="GET_ARTICLES.data"> <!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_ARTICLES.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
				</div>
			</div>
			<unit-type-component v-if="tab === 3"/>
		</div>
		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import debounce from 'lodash.debounce'
import download from 'downloadjs'
import bus from './../../bus'
import UnitTypeComponent from "./components/UnitTypeComponent";
import ArticleTable from './components/ArticleTable.vue'
import ArticleSpreadSheet from './components/ArticleSpreadSheetTable.vue'
import {getCompanyId} from "../../services/storage-window";

export default {
	name: 'article-settings',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		FilterProductComponent: () => import('./components/FilterProductComponent.vue'),
		paginate: Paginate,
		ArticleSpreadSheet,
		ArticleTable,
		UnitTypeComponent,
	},
	data() {
		return {
			imageBaseServerURL: 'https://api.b2bportalen.se/media/',
			loading: true,
			limit: 100,
			tab: 1,
			statusUpdate: false,
			showUndoBtn: false,
			showUpdateBtn: false,
			checkboxAll: false,
			showFilter: false,
			openDownloadFilter: false,
			showTopFilter: false,
			updating: false,
			priceList: {id: ''},
			updatingPricelist: false,
			showPricelistSelect: false,
			bulkSelect: '',
			selectedRow: [],
			searchName: '',
			isRelevant: '',
			isHidden: '',
			hasImages: '',
			isCustomerSpecific: '',
		}
	},
	watch: {
		limit: function (newVal, oldVal) {
			this.limit = newVal
			window.localStorage.setItem('articlenum', newVal)
		},
		showFilter: function (newVl, _) {
			if (newVl === false) {
				// this.hotRef.loadData(this.GET_ARTICLES.data, 'edit')
				// // setTimeout(() => {
				// // }, 1000)
			}
		},
	},
	computed: {
		...mapGetters({
			GET_ARTICLES: 'portal/GET_ARTICLES',
			GET_PRICE_LIST: 'portal/GET_PRICE_LIST',
			GET_COMPANY_SETTING: 'portal/GET_COMPANY_SETTING',
			GET_ARTICLES_CHECKED: 'portal/GET_ARTICLES_CHECKED',
			GET_SINGLE_ARTICLE_CHECKED: 'portal/GET_SINGLE_ARTICLE_CHECKED',
			GET_ALL_CUSTOMERS: 'portal/GET_ALL_CUSTOMERS',
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
			GET_ARTICLE_ACTIVE_FILTER: 'portal/GET_ARTICLE_ACTIVE_FILTER',
			GET_ARTICLE_PRODUCT_PRICE_FILTER: 'portal/GET_ARTICLE_PRODUCT_PRICE_FILTER'
		})
	},
	mounted() {
		const self = this

		this.getArticles(this.limit)

		// Handsontable.renderers.registerRenderer('applyMargin', this.applyMargin)

		window.Bus.$on('fetch-article', e => {
			if (e === 'bulk') {
				self.bulkSelect = ''
				self.checkboxAll = false
			}
			this.getArticles(this.limit)
		})

		window.Bus.$on('fetch-product-category', _ => {
			this.getProductCategory()
		})

		window.Bus.$on('reset-articles', _ => {
			// self.hotRef.loadData(this.GET_ARTICLES.data, 'edit')
			this.isRelevant = ''
			this.isHidden = ''
			this.hasImages = ''
			this.isCustomerSpecific = ''
			this.getArticles(this.limit)
		})

		window.Bus.$on('load-request', _ => {
			this.loading = true
		})

		window.Bus.$on('finish-request', _ => {
			this.loading = false
			// self.hotRef.loadData(this.GET_ARTICLES.data, 'edit')
		})
		window.Bus.$on('update-editable-table', _ => {
			this.statusUpdate = true
		})
		window.Bus.$on('filterArticleRelevant', e => {
			this.filterArticleWithRelevant(e)
		})
		window.Bus.$on('clear-filter-data', _ => {
			this.searchName = ''
			this.isRelevant = ''
		})

		window.Bus.$on('filter-article-tab-1', _ => {
			this.advanceArticleFilter()
		})
		window.Bus.$on('filter-article-tab-2', _ => {
			this.filterProductPrice()
		})
		window.Bus.$on('reload-article', _ => {
			this.filterProductPrice()
		})
		window.Bus.$on('remove-article', e => {
			let index = this.GET_ARTICLES.data.findIndex(item => parseInt(item.id) === parseInt(e))
			if (index > -1) {
				this.GET_ARTICLES.data.splice(index, 1)
			}
		})
	},
	methods: {
		sortArticle: function (sortValue) {
			this.loading = true
			let pageUrl = `?limit=${this.limit}&ordering=${sortValue}`
			if (this.searchName !== '') {
				pageUrl += `&search=${this.searchName}`
			}
			if (this.tab === 1) {
				this.GET_ARTICLE_ACTIVE_FILTER.map(item => {
					if (item.value !== '') {
						pageUrl += `&${item.mode}=${item.value}`
					}
				})
			} else if (this.tab === 2) {
				this.GET_ARTICLE_PRODUCT_PRICE_FILTER.map(item => {
					if (['vat_rate'].includes(item.mode) === true) {
						pageUrl += `&${item.mode}=${item.value}`
					} else if (['sales', 'cost', 'quantity'].includes(item.mode) === true) {
						if (item.value.range === 'between') {
							let payload = {
								sales: item.value.start > item.value.end ? `&sales_price_from=${parseInt(item.value.end)}&sales_price_to=${parseInt(item.value.start)}` : `&sales_price_from=${parseInt(item.value.start)}&sales_price_to=${parseInt(item.value.end)}`,
								cost: item.value.start > item.value.end ? `&purchase_price_from=${parseInt(item.value.end)}&purchase_price_to=${parseInt(item.value.start)}` : `&purchase_price_from=${parseInt(item.value.start)}&purchase_price_to=${parseInt(item.value.end)}`,
								quantity: item.value.start > item.value.end ? `&quantity_in_stock_from=${parseInt(item.value.end)}&quantity_in_stock_to=${parseInt(item.value.start)}` : `&quantity_in_stock_from=${parseInt(item.value.start)}&quantity_in_stock_to=${parseInt(item.value.end)}`,
							}
							pageUrl += `${payload[item.mode]}`
						} else {
							let payload = {
								sales: `&sales_price=${item.value.value}`,
								cost: `&purchase_price=${item.value.value}`,
								quantity: `&quantity_in_stock=${item.value.value}`,
							}
							pageUrl += `${payload[item.mode]}`
						}
					}
				})
			}
			this.$store.dispatch('portal/getArticles', {url: pageUrl})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		removeItemFromArticleFilter(item) {
			this.$store.commit('portal/REMOVE_ARTICLE_ACTIVE_FILTER', item)
			this.advanceArticleFilter()
		},
		removeItemFromArticlePriceFilter(item) {
			this.$store.commit('portal/REMOVE_ARTICLE_PRODUCT_PRICE_FILTER', item)
			this.filterProductPrice()
		},
		hideStatus() {
			window.Bus.$emit('remove-editable-input')
			this.statusUpdate = false
		},
		openArticleUpdate(data) {
			bus.$emit('open-article-update', data)
		},
		oNopenDownloadFilter() {
			this.openDownloadFilter = !this.openDownloadFilter
		},
		executeExcelAction(value) {
			this.openDownloadFilter = false
			if (value === 'download_excel_product') {
				this.downloadExcelProduct()
			} else if (value === 'upload_excel_product') {
				window.Bus.$emit('open-excel-bulk-upload')
			}
		},
		downloadExcelProduct() {
			const loading = this.$loading({
				lock: true,
				text: this.$t('pleaseWait'),
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})
			let URL = `/download/?company_id=${getCompanyId()}`
			this.GET_ARTICLE_ACTIVE_FILTER.map(item => {
				if (item.value !== '') {
					if (['customer_specific'].includes(item.mode) === true) {
						URL += `&is_customer_specific=${item.value}`
					} else {
						URL += `&${item.mode}=${item.value}`
					}
				}
			})

			this.$store.dispatch('portal/downloadArticles', {url: URL}).then(res => {
				const content = res.headers['content-type']
				download(res.data, 'b2b_product.xlsx', content)
				loading.close()
				this.openDownloadFilter = false
			}).catch(() => {
				loading.close()
				this.openDownloadFilter = false
				this.$services.helpers.notification('Error exporting product data', 'error', this)
			})
		},
		openArticleModal(data) {
			bus.$emit('open-article-modal', data)
		},
		openAssignProduct() {
			if (this.GET_SINGLE_ARTICLE_CHECKED && this.GET_SINGLE_ARTICLE_CHECKED.status) {
				this.openArticleCategoryModal()
			} else {
				window.Bus.$emit('open-assign-product-category', {state: 'multiple', key: new Date().getTime()})
			}


		},
		toggleAllCheckbox(e) {
			this.$store.commit('portal/TOGGLE_ALL_CHECKBOX', this.checkboxAll)
		},
		addProductCategory() {
			window.Bus.$emit('open-add-product')
		},
		addSubCategory() {
			window.Bus.$emit('open-add-sub-product')
		},
		openModalImage(data) {
			window.Bus.$emit('open-image-modal', data)
		},
		filterArticleWithRelevant(data) {
			this.getArticlesCopy(data)
		},
		openArticleEditModal() {
			window.Bus.$emit('open-article-edit-modal', this.GET_SINGLE_ARTICLE_CHECKED.data[0])
		},
		openArticleCategoryModal() {
			window.Bus.$emit('open-article-edit-category-modal', {
				data: this.GET_SINGLE_ARTICLE_CHECKED.data[0],
				key: new Date().getTime(),
				state: 'single'
			})
		},
		openCategoryDrawer() {
			window.Bus.$emit("change-article-category-key", true)
			return this.$store.commit('utility/OPEN_SIDEDRAWER')
		},
		searchInputTrigger: debounce(function (event) {
			if (this.searchName !== '') {
				this.loading = true
				let URL = `?limit=${this.limit}&search=${this.searchName}`
				if (getCompanyId()) {
					URL += `&company_id=${getCompanyId()}`
				}
				this.$store.dispatch('portal/getArticles', {url: URL})
					.then(res => {
						this.loading = false
						if (!res.data.price_list_set) {
							this.showPricelistSelect = true
							window.Bus.$emit('message', {data: this.$t('selectPricelistIndicator')})
						} else {
							this.showPricelistSelect = false
						}
					}).catch((err) => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
			} else {
				this.searchName = ''
				this.getArticles(this.limit)
			}
			if (this.tab === 2) {
				// setTimeout(() => {
				//     // this.hotRef.loadData(this.GET_ARTICLES.data, 'edit')
				// }, 500)
			}
		}, 500),
		filterSearch(event) {
			this.$store.commit('portal/FILTER_SEARCH', {value: event.target.value})
		},
		getProductCategory() {
			this.$store.dispatch('portal/getProductCategory')
		},
		getActiveAccounts() {
			this.$store.dispatch('portal/getActiveAccounts')
		},
		hideAllFilter() {
			this.showFilter = false
			this.showTopFilter = false
		},
		changePageNumber(number) {
			this.loading = true
			let pageUrl = `?page=${number}&limit=${this.limit}`
			if (this.searchName !== '') {
				pageUrl += `&search=${this.searchName}`
			}
			if (this.tab === 1) {
				this.GET_ARTICLE_ACTIVE_FILTER.map(item => {
					if (item.value !== '') {
						pageUrl += `&${item.mode}=${item.value}`
					}
				})
			} else if (this.tab === 2) {
				this.GET_ARTICLE_PRODUCT_PRICE_FILTER.map(item => {
					if (['vat_rate'].includes(item.mode) === true) {
						pageUrl += `&${item.mode}=${item.value}`
					} else if (['sales', 'cost', 'quantity'].includes(item.mode) === true) {
						if (item.value.range === 'between') {
							let payload = {
								sales: item.value.start > item.value.end ? `&sales_price_from=${parseInt(item.value.end)}&sales_price_to=${parseInt(item.value.start)}` : `&sales_price_from=${parseInt(item.value.start)}&sales_price_to=${parseInt(item.value.end)}`,
								cost: item.value.start > item.value.end ? `&purchase_price_from=${parseInt(item.value.end)}&purchase_price_to=${parseInt(item.value.start)}` : `&purchase_price_from=${parseInt(item.value.start)}&purchase_price_to=${parseInt(item.value.end)}`,
								quantity: item.value.start > item.value.end ? `&quantity_in_stock_from=${parseInt(item.value.end)}&quantity_in_stock_to=${parseInt(item.value.start)}` : `&quantity_in_stock_from=${parseInt(item.value.start)}&quantity_in_stock_to=${parseInt(item.value.end)}`,
							}
							pageUrl += `${payload[item.mode]}`
						} else {
							let payload = {
								sales: `&sales_price=${item.value.value}`,
								cost: `&purchase_price=${item.value.value}`,
								quantity: `&quantity_in_stock=${item.value.value}`,
							}
							pageUrl += `${payload[item.mode]}`
						}
					}
				})
			}
			this.$store.dispatch('portal/getArticles', {url: pageUrl})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		advanceArticleFilter() {
			this.loading = true
			let pageUrl = `?limit=${this.limit}`
			this.GET_ARTICLE_ACTIVE_FILTER.map(item => {
				if (item.value !== '') {
					if (['customer_specific'].includes(item.mode) === true) {
						pageUrl += `&is_customer_specific=${item.value}`
					} else {
						pageUrl += `&${item.mode}=${item.value}`
					}


				}
			})
			if (this.searchName !== '') {
				pageUrl += `&search=${this.searchName}`
			}
			if (getCompanyId()) {
				pageUrl += `&company_id=${getCompanyId()}`
			}
			this.$store.dispatch('portal/getArticles', {url: pageUrl})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		updateArticleQuantity(data) {
			this.$store.commit('portal/UPDATE_ARTICLE_QUANTITY', data)
			const indexObj = this.GET_ARTICLES.data.findIndex(item => item.id === parseInt(data.row))
			const artObj = this.GET_ARTICLES.data[indexObj]
			const payload = {
				article_id: data.row,
				quantity_in_stock: parseInt(data.value),
				article_number: artObj.article_number,
				product_name: artObj.product_name,
				description: artObj.description,
				sales_price: parseFloat(artObj.sales_price),
				purchase_price: parseFloat(artObj.purchase_price)
			}
			this.$store.dispatch('portal/updateArticle', payload).then(_ => {
				if (indexObj > -1) {
					const obj = this.GET_ARTICLES.data[indexObj]
					obj.quantity_in_stock = payload.quantity_in_stock
				}
			}).catch(err => {
				window.Bus.$emit('output-error', err)
			})
		},
		updateArticlePrice(data) {

			const artObj = this.GET_ARTICLES.data[data.id]
			const payload = {
				article_id: artObj.id,
				quantity_in_stock: artObj.quantity_in_stock,
				article_number: artObj.article_number,
				product_name: artObj.product_name,
				description: artObj.description,
				sales_price: parseFloat(data.salesPrice),
				purchase_price: parseFloat(artObj.purchase_price)
			}
			this.$store.dispatch('portal/updateArticle', payload).then(_ => {
				if (data.id > -1) {
					const obj = this.GET_ARTICLES.data[data.id]
					obj.sales_price = payload.sales_price
				}
				this.$store.commit('portal/UPDATE_MARGIN', data)
			}).catch(err => {
				window.Bus.$emit('output-error', err)
			})
		},
		updateArticleCostPrice(data) {
			this.$store.commit('portal/UPDATE_SALES_MARGIN', data)
			// const indexObj = this.GET_ARTICLES.data.findIndex(item => item.id === parseInt(data.id))
			const artObj = this.GET_ARTICLES.data[data.id]
			const payload = {
				article_id: artObj.id,
				quantity_in_stock: artObj.quantity_in_stock,
				article_number: artObj.article_number,
				product_name: artObj.description,
				description: artObj.description,
				sales_price: parseFloat(artObj.sales_price),
				purchase_price: parseFloat(data.costPrice)
			}
			this.$store.dispatch('portal/updateArticle', payload).then(_ => {
				if (data.id > -1) {
					const obj = this.GET_ARTICLES.data[data.id]
					obj.purchase_price = payload.purchase_price
				}
			}).catch(err => {
				window.Bus.$emit('output-error', err)
			})
		},
		updateArticleMargin(data) {
			this.$store.commit('portal/UPDATE_SALESPRICE_EX_VAT', data)
		},
		getPriceList() {
			let URL = ''
			if (getCompanyId()) {
				URL += `?company_id=${getCompanyId()}`
			}
			this.$store.dispatch('portal/getPriceList', URL)
		},
		getArticles(limit = null) {
			this.loading = true
			let URL = `?limit=${limit}`
			if (getCompanyId()) {
				URL += `&company_id=${getCompanyId()}`
			}
			this.$store.dispatch('portal/getArticles', {url: URL})
				.then(res => {
					this.loading = false
					this.getActiveAccounts()
					this.getAllEndCustomers()
					this.getProductCategory()
					this.getCompanyPricelist()
					this.getPriceList()
					const priceList = this.GET_PRICE_LIST
					// if (!res.data.price_list_set) {
					// 	this.showPricelistSelect = true
					// 	if (priceList.length > 0) {
					// 		this.autoUpdateArticlePricelist(priceList[0].id)
					// 	}
					// }
					if (this.loading === false) {
						window.Bus.$emit('start-article-tour')
					}
				}).catch((err) => {
				this.loading = false
				try {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				} catch (e) {}
			})
		},
		getArticlesCopy(data) {
			this.loading = true
			let page = `?limit=${this.limit}`
			if (data.type === 'relevant') {
				this.isHidden = ''
				this.isRelevant = data.status

				page = page + `&is_relevant=${data.status}`
				this.$store.commit('portal/SET_ARTICLE_ACTIVE_FILTER', {mode: 'is_relevant', value: data.status})
			} else if (data.type === 'customer_specific') {
				page = page + `&is_hidden=${data.status}`
				this.isHidden = data.status
				this.isRelevant = ''
				this.$store.commit('portal/SET_ARTICLE_ACTIVE_FILTER', {mode: 'customer_specific', value: data.status})
			}
			this.$store.dispatch('portal/getArticles', {url: page})
				.then(res => {
					this.loading = false
					const priceList = this.GET_PRICE_LIST
					if (!res.data.price_list_set) {
						this.showPricelistSelect = true
						if (priceList.length > 0) {
							this.autoUpdateArticlePricelist(priceList[0].id)
						} else {
							this.autoUpdateArticlePricelist(priceList[0].id)
						}
					} else {
						this.showPricelistSelect = false
					}
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changeTab(number) {
			if (number === 2) {
				this.tab = number
				this.$store.commit('portal/RESET_ARTICLES', null)
			} else {
				this.tab = number
				this.$store.commit('portal/RESET_ARTICLES', null)
			}
		},
		updateBulkData() {
			this.updating = true
			const updatedObject = {
				articles: []
			}
			this.GET_ARTICLES_CHECKED.data.forEach(element => {
				const obj = {}
				obj.article_id = element.id
				obj.article_number = element.article_number
				obj.purchase_price = element.purchase_price
				obj.quantity_in_stock = element.quantity_in_stock
				obj.price = element.price
				// obj.margin = element.margin
				obj.vat_rate = element.vat_rate
				updatedObject.articles.push(obj)
			})
			this.$store.dispatch('portal/updateBulkData', {data: updatedObject})
				.then(_ => {
					this.updating = false
					this.$services.helpers.notification(this.$t('articleUpdateSuccess'), 'success', this)
				})
				.catch(err => {
					this.updating = false
					this.$services.helpers.notification(err.response.data.error, 'error', this)
				})
		},
		updateArticlePricelist(e) {
			this.updatingPricelist = true
			const payload = {
				id: e.target.value
			}
			this.$store.dispatch('portal/updateCompanyPricelist', payload)
				.then(_ => {
					this.getArticles(this.limit)
					this.updatingPricelist = false
					this.showPricelistSelect = false
					this.$services.helpers.notification(this.$t('pricelistUpdated'), 'success', this)
				})
				.catch(_ => {
					this.updatingPricelist = false
					this.$services.helpers.notification(err.response.data, 'error', this)
				})
		},
		autoUpdateArticlePricelist(id) {
			this.updatingPricelist = true
			const payload = {
				id: id
			}
			if (getCompanyId()) {
				payload['company_id'] = getCompanyId()
			}
			this.$store.dispatch('portal/updateCompanyPricelist', payload)
				.then(_ => {
					this.getArticles(this.limit)
					this.updatingPricelist = false
					this.showPricelistSelect = false
					// this.$services.helpers.notification(this.$t('pricelistUpdated'), 'success', this)
				})
				.catch(_ => {
					this.updatingPricelist = false
					this.$services.helpers.notification(err.response.data, 'error', this)
				})
		},
		getCompanyPricelist() {
			this.$store.dispatch('portal/getCompanyDetails')
				.then(lok => {
					this.priceList = this.GET_COMPANY_SETTING.price_list ? this.GET_COMPANY_SETTING.price_list : {}
					if (this.GET_COMPANY_SETTING.price_list) {
						// if (this.GET_COMPANY_SETTING.price_list.id) {
						// 	this.priceList = this.GET_COMPANY_SETTING.price_list
						//
						// 	// If company has pricelist fetch articles
						// 	const select = window.localStorage.getItem('articlenum')
						// 	if (select) {
						// 		if (parseInt(select)) {
						// 			this.limit = select
						// 			this.getArticles(this.limit)
						// 		} else {
						// 			this.limit = 100
						// 			window.localStorage.setItem('articlenum', this.limit)
						// 			this.getArticles(this.limit)
						// 		}
						// 	} else {
						// 		window.localStorage.setItem('articlenum', this.limit)
						// 		this.getArticles(this.limit)
						// 	}
						// } else {
						// 	this.loading = false
						// 	const priceList = this.GET_PRICE_LIST
						// 	try {
						// 		this.autoUpdateArticlePricelist(priceList[0].id)
						// 	} catch (_) {
						// 	}
						// }
					} else {
						this.loading = false
					}
				})
				.catch(err => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		filterRelevantArticles(data) {
			this.loading = true
			const payload = {
				query: `is_relevant=${data.name}`
			}
			this.$store.commit('portal/SET_ARTICLE_ACTIVE_FILTER', {mode: 'is_relevant', value: data.name})
			this.$store.dispatch('portal/advanceFilterArticle', payload)
				.then(_ => {
					this.loading = false
				})
				.catch(_ => {
					this.loading = false
				})
		},
		filterVatRate(data) {
			if (parseInt(data.value)) {
				this.loading = true
				const payload = {
					query: `vat_rate=${data.value}`
				}

				this.$store.dispatch('portal/advanceFilterArticle', payload)
					.then(_ => {
						this.loading = false
						// this.hotRef.loadData(this.GET_ARTICLES.data, 'edit')
					})
					.catch(_ => {
						this.loading = false
					})
			}
		},
		filterSalesPrice(data) {
			const query = data.start > data.end ? `sales_price_from=${parseInt(data.end)}&sales_price_to=${parseInt(data.start)}` : `sales_price_from=${parseInt(data.start)}&sales_price_to=${parseInt(data.end)}`
			this.loading = true
			const payload = {
				query: query
			}
			this.$store.dispatch('portal/advanceFilterArticle', payload)
				.then(_ => {
					this.loading = false
				})
				.catch(_ => {
					this.loading = false
				})
		},
		filterCostPrice(data) {
			const query = data.start > data.end ? `cost_price_from=${parseInt(data.end)}&cost_price_to=${parseInt(data.start)}` : `cost_price_from=${parseInt(data.start)}&cost_price_to=${parseInt(data.end)}`
			this.loading = true
			const payload = {
				query: query
			}
			this.$store.dispatch('portal/advanceFilterArticle', payload)
				.then(_ => {
					this.loading = false
				})
				.catch(_ => {
					this.loading = false
				})
		},
		filterQuantity(data) {
			const query = data.start > data.end ? `quantity_in_stock_from=${parseInt(data.end)}&quantity_in_stock_to=${parseInt(data.start)}` : `quantity_in_stock_from=${parseInt(data.start)}&quantity_in_stock_to=${parseInt(data.end)}`
			this.loading = true
			const payload = {
				query: query
			}
			this.$store.dispatch('portal/advanceFilterArticle', payload)
				.then(_ => {
					this.loading = false
				})
				.catch(_ => {
					this.loading = false
				})
		},
		filterProductPrice() {
			this.loading = true
			let filters = `limit=${this.limit}`
			this.GET_ARTICLE_PRODUCT_PRICE_FILTER.map(item => {
				if (['vat_rate'].includes(item.mode) === true) {
					filters += `&${item.mode}=${item.value}`
				} else if (['sales', 'cost', 'quantity'].includes(item.mode) === true) {
					if (item.value.range === 'between') {
						let payload = {
							sales: item.value.start > item.value.end ? `&sales_price_from=${parseInt(item.value.end)}&sales_price_to=${parseInt(item.value.start)}` : `&sales_price_from=${parseInt(item.value.start)}&sales_price_to=${parseInt(item.value.end)}`,
							cost: item.value.start > item.value.end ? `&purchase_price_from=${parseInt(item.value.end)}&purchase_price_to=${parseInt(item.value.start)}` : `&purchase_price_from=${parseInt(item.value.start)}&purchase_price_to=${parseInt(item.value.end)}`,
							quantity: item.value.start > item.value.end ? `&quantity_in_stock_from=${parseInt(item.value.end)}&quantity_in_stock_to=${parseInt(item.value.start)}` : `&quantity_in_stock_from=${parseInt(item.value.start)}&quantity_in_stock_to=${parseInt(item.value.end)}`,
						}
						filters += `${payload[item.mode]}`
					} else {
						let payload = {
							sales: `&sales_price=${item.value.value}`,
							cost: `&purchase_price=${item.value.value}`,
							quantity: `&quantity_in_stock=${item.value.value}`,
						}
						filters += `${payload[item.mode]}`
					}
				}
			})
			if (getCompanyId()) {
				filters += `&company_id=${getCompanyId()}`
			}
			this.$store.dispatch('portal/advanceFilterArticle', {query: filters})
				.then(_ => {
					this.loading = false
					// check if margin is inside the save filter and filter by margin
					let marginExist = this.GET_ARTICLE_PRODUCT_PRICE_FILTER.filter(item => item.mode === 'margin')
					if (marginExist.length > 0) {
						if (marginExist[0].value.range && (parseInt(marginExist[0].value.value) || parseInt(marginExist[0].value.start) || parseInt(marginExist[0].value.end))) {
							this.$store.commit('portal/ADVANCE_FILTER_ARTICLE_MARGIN', marginExist[0])
						}
					}
				})
				.catch(_ => {
					this.loading = false
				})
		},
		getAllEndCustomers() {

			this.$store.dispatch('portal/getAllEndCustomers')
		},
		relevantArticlesProcess(value) {
			if (value.data === 'select') {
				window.Bus.$emit('open-relevant-article-modal', value)
			} else {
				this.updateSingleRelevantArticle(value, 'single')
			}
		},
		filterArticleWithImage(data) {
			if ('status' in data) {
				this.hasImages = data.status
				this.loading = true
				this.$store.commit('portal/SET_ARTICLE_ACTIVE_FILTER', {mode: 'has_images', value: data.status})
				this.$store.dispatch('portal/getArticles', {url: `?limit=${this.limit}&has_images=${data.status}`})
					.then(() => {
						this.loading = false
					}).catch((err) => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
			}
		},
		filterCustomerSpecific(data) {
			if ('status' in data) {
				this.isCustomerSpecific = data.status
				this.loading = true
				this.$store.commit('portal/SET_ARTICLE_ACTIVE_FILTER', {mode: 'customer_specific', value: data.status})
				this.$store.dispatch('portal/getArticles', {url: `?limit=${this.limit}&is_customer_specific=${data.status}`})
					.then(() => {
						this.loading = false
					}).catch((err) => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
			}
		},
		updateSingleRelevantArticle(value, tmp) {
			const payload = {
				articles: []
			}
			if (tmp === 'single') {
				if (value.data === 'true') {
					payload.is_relevant_update = true
					payload.articles.push({article_id: parseInt(value.article)})
				} else {
					payload.is_relevant_update = false
					payload.articles.push({article_id: parseInt(value.article)})
				}
			} else {
				if (value === 'true') {
					payload.is_relevant_update = true
					this.GET_ARTICLES_CHECKED.data.forEach(element => {
						payload.articles.push({article_id: parseInt(element.id)})
					})
				} else {
					payload.is_relevant_update = false
					this.GET_ARTICLES_CHECKED.data.forEach(element => {
						payload.articles.push({article_id: parseInt(element.id)})
					})
				}
			}
			if (getCompanyId()) {
				payload['company_id'] = getCompanyId()
			}
			this.$store.dispatch('portal/updateRelevantBulkData', {data: payload})
				.then(_ => {
					this.$services.helpers.notification(this.$t('articleUpdateSuccess'), 'success', this)
					// this.getArticles(this.limit)
					if (tmp === 'bulk') {
						this.bulkSelect = ''
						this.checkboxAll = false
					}
					if (tmp === 'single') {
						const articleIndex = this.GET_ARTICLES.data.findIndex(item => item.id === value.article)
						if (value.data === 'true') {
							this.GET_ARTICLES.data[articleIndex].is_relevant = true
						} else {
							this.GET_ARTICLES.data[articleIndex].is_relevant = false
						}
					}
				})
				.catch(err => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		bulkSetRelevant(e) {
			if (e.target.value === 'select') {
				window.Bus.$emit('open-relevant-article-modal')
			} else {
				this.updateSingleRelevantArticle(e.target.value, 'bulk')
			}
		},
		shopResultNumber(e) {
			if (this.GET_COMPANY_SETTING.price_list) {
				if (this.GET_COMPANY_SETTING.price_list.id) {
					this.limit = parseInt(e.target.value)
					this.getArticles(parseInt(e.target.value))
				}
			}
		},
		sortAscNumber() {
			this.$store.commit('portal/SORT_ARTICLE_NUMBER_ASC')
		},
		sortDescNumber() {
			this.$store.commit('portal/SORT_ARTICLE_NUMBER_DESC')
		},
		sortAscName() {
			this.$store.commit('portal/SORT_ARTICLE_NAME_ASC')
		},
		sortDescName() {
			this.$store.commit('portal/SORT_ARTICLE_NAME_DSC')
		},
		sortAscDesc() {
			this.$store.commit('portal/SORT_ARTICLE_DESC_ASC')
		},
		sortArticleAscNumber() {
			this.$store.commit('portal/SORT_ARTICLE_NUMBER_ASC')
		},
		sortArticleDescNumber() {
			this.$store.commit('portal/SORT_ARTICLE_NUMBER_DESC')
		},
		sortDescDesc() {
			this.$store.commit('portal/SORT_ARTICLE_DESC_DSC')
		},
		sortImageAsc() {
			this.$store.commit('portal/SORT_ARTICLE_IMAGE_ASC')
		},
		sortImageDesc() {
			this.$store.commit('portal/SORT_ARTICLE_IMAGE_DSC')
		},
		sortVideoAsc() {
			this.$store.commit('portal/SORT_ARTICLE_VIDEO_ASC')
		},
		sortVideoDesc() {
			this.$store.commit('portal/SORT_ARTICLE_VIDEO_DSC')
		},
		sortAscQuantity() {
			this.$store.commit('portal/SORT_ARTICLE_QUANTITY_ASC')
		},
		sortQuantityDesc() {
			this.$store.commit('portal/SORT_ARTICLE_QUANTITY_DESC')
		},
		sortAscPrice() {
			this.$store.commit('portal/SORT_ARTICLE_SALESPRICE_ASC')
		},
		sortPriceDesc() {
			this.$store.commit('portal/SORT_ARTICLE_SALESPRICE_DESC')
		},
		sortAscCostPrice() {
			this.$store.commit('portal/SORT_ARTICLE_COST_PRICE_ASC')
		},
		sortCostPriceDesc() {
			this.$store.commit('portal/SORT_ARTICLE_COST_PRICE_DESC')
		},
		sortAscVateRate() {
			this.$store.commit('portal/SORT_ARTICLE_VAT_RATE_ASC')
		},
		sortVateRateDesc() {
			this.$store.commit('portal/SORT_ARTICLE_VAT_RATE_DESC')
		},
		sortAscMargin() {
			this.$store.commit('portal/SORT_ARTICLE_MARGIN_ASC')
		},
		sortMarginDesc() {
			this.$store.commit('portal/SORT_ARTICLE_MARGIN_DESC')
		},
		openCreateArticle: function () {
			window.Bus.$emit('open-create-article')
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table th {
	// padding: 0.1rem 0.55rem !important;
	color: #000000 !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-select {
	border: 1px solid #DFE0EB !important;
	height: unset !important;
	padding: .175rem 1.5rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.table-striped tbody:nth-of-type(2n+1) {
	background-color: rgba(0, 0, 0, 0.03);
}

.c-border {
	border-width: 1px !important;
}

.div-action {
	display: flex;
	justify-content: space-around;
}

.btn-orange {
	background: #e8b105;
	border: 1px solid #e8b105 !important;
	color: #fff;
}

@media only screen and (max-width: 640px) {
	.div-action {
		display: block;
		justify-content: normal;
	}
}

</style>
